<template>
  <div>
    <iframe id="home"
            :src="url"
            frameborder="0"
            scrolling="yes"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "homepage",
  data() {
    return {
      url: `http://${this.$store.state.monitor.lab3}:3000/d/OzCkPq17z/zhongmeipage?orgId=1&var-node=lab3%3A8100&theme=light&kiosk`
    }
  },
  mounted() {
    function changeMobsfIframe(){
      const iframe = document.getElementById('home');
      const deviceWidth = document.body.clientWidth;
      const deviceHeight = document.body.clientHeight;
      iframe.style.width = (Number(deviceWidth)-120) + 'px'; //数字是页面布局宽度差值
      iframe.style.height = (Number(deviceHeight)-160) + 'px'; //数字是页面布局高度差
    }
    changeMobsfIframe()
    window.onresize = function(){
      changeMobsfIframe()
    }
  },
  methods: {
  }
}
</script>
